globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"3f54462fddf63b592e00cbf9875945885a131d70"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import sentryBasedConfigs, {
  isSentryConfigAvailable
} from './sentryBasedConfigs';

if (isSentryConfigAvailable) {
  Sentry.init({
    ...sentryBasedConfigs
  });
}
